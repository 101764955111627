import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TICKET_CONSTANTS } from "./ticket-constants";

export const ticketsSlice = createApi({
  reducerPath: "ticketsSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchResponses: builder.query({
        query(id) {
          return `tickets/responses?ticket_id=${id}&orderBy=id,desc`;
        },
        providesTags: [TICKET_CONSTANTS.RESPONSE],
      }),
      submitResponse: builder.mutation({
        query(body) {
          return {
            url: "tickets/responses",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [TICKET_CONSTANTS.RESPONSE],
      }),
    };
  },
});

export const { useLazyFetchResponsesQuery } = ticketsSlice;
